html, body {
  color: #000c;
  height: 100%;
}

header, main {
  padding: 5vw;
}

header {
  min-height: 70vh;
}

nav > ul {
  margin: 1ch 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1em;
}

h1 {
  font-size: 2.5rem;
  line-height: 2em;
}

h2 {
  margin-bottom: .5ch;
  font-size: 1.75rem;
}

h3 {
  font-size: 1.25rem;
}

a {
  font: inherit;
}

ul {
  padding-left: 1.5ch;
  list-style: square;
  overflow: hidden;
}

ul li {
  display: list-item;
}

ul :nth-of-type(2n) {
  list-style: disc;
}

section, .section {
  margin-bottom: 3vh;
}

#btt {
  color: inherit;
  background-color: #fffc;
  border-radius: 50%;
  padding: 2rem;
  text-decoration: none;
  position: fixed;
  bottom: 1ch;
  right: 1ch;
}

@media screen and (width >= 1200px) and (orientation: landscape) {
  #btt {
    transition: scale .25s ease-in-out;
  }
}

#btt:hover {
  scale: 110%;
}

.me {
  aspect-ratio: 1;
  float: left;
  object-fit: cover;
  mix-blend-mode: luminosity;
  opacity: .25;
  order: 0;
  width: auto;
  max-width: 100%;
  max-height: 50vh;
  margin-right: 2em;
}

.project {
  border: 1px solid #0003;
  margin-bottom: 1em;
  padding: 2rem;
  overflow: auto;
}

.project img, .project video, .project object {
  float: left;
  clear: both;
  object-fit: cover;
  max-width: 100%;
  height: auto;
  max-height: 60vh;
  margin-bottom: 1em;
  margin-right: 1em;
}

.project-date, .subtext {
  color: #000000b3;
  font-size: 1rem;
  display: inline-block;
}

.project-role {
  display: block;
}

.project-description {
  margin: .5ch 0;
}

.releases {
  flex-flow: wrap;
  gap: 1em;
  margin-top: 1em;
  display: flex;
}

.releases > * {
  flex: 360px;
}
/*# sourceMappingURL=index.17d0addc.css.map */
