$black: hsla(0, 0%, 0%, 80%);
$less-black: hsla(0, 0%, 0%, 70%);
$white-overlay: hsla(0, 0%, 100%, 80%);
$shadow: hsla(0, 0%, 0%, 25%);
html,body {
    height: 100%;
    color: $black;
}
header, main {
    padding: 5vw;
}
header {
    min-height: 70vh;
}
nav > ul {
    margin: 1ch 0;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1em;
    font-weight: 600;
}
h1 { font-size: 2.5rem; line-height: 2em; }
h2 { font-size: 1.75rem; margin-bottom: 0.5ch; }
h3 { font-size: 1.25rem; }

a {
    // So that links in h1-6 tags still look like h1-6 tags
    font: inherit;
}

a:link {}
a:local-link {}
a:visited {}
a:active {}

ul {
    overflow: hidden; // Hack to get list-items to respect floated image
    padding-left: 1.5ch;
    list-style: square;
    li {
        display: list-item;
    }
    :nth-of-type(2n) {
        list-style: disc;
    }
}

section, .section {
    margin-bottom: 3vh;
}

#btt {
    position: fixed;
    bottom: 1ch;
    right: 1ch;

    padding: 2rem;
    border-radius: 50%;
    background-color: $white-overlay;

    text-decoration: none;
    color: inherit;

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        transition: scale 0.25s ease-in-out;
    }

    &:hover {
        scale: 110%;
    }
}

.me {
    max-width: 100%;
    max-height: 50vh;
    width: auto;
    aspect-ratio: 1;
    float: left;
    margin-right: 2em;
    object-fit: cover;
    mix-blend-mode: luminosity;
    opacity: 0.25;
    order: 0;
}
.project {
    border: 1px solid hsla(0, 0%, 0%, 20%);
    padding: 2rem; 

    overflow: auto;
    margin-bottom: 1em;
    img, video, object {
        float: left;
        clear: both;
        max-width: 100%;
        height: auto;
        max-height: 60vh;
        margin-right: 1em;
        margin-bottom: 1em;
        object-fit: cover;
    }
}
.project-date, .subtext {
    display: inline-block;
    color: $less-black;
    font-size: 1rem;
}
.project-role {
    display: block;
}
.project-description {
    margin: 0.5ch 0;
}

.releases {
    margin-top: 1em;
    display: flex;
    flex-flow: row wrap;
    gap: 1em;
    & > * {
        flex: 1;
        flex-basis: 360px;
    }
}